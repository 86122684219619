<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Dados do evento</h4>
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="name">
                    Nome do evento
                    <span id="tooltip-name-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-name-event" triggers="hover">
                      Informe um nome para o seu evento
                    </b-tooltip>
                  </label>
                  <input
                    id="name"
                    v-model="event.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.event.name.required"
                    class="invalid-feedback"
                  >
                    O campo nome é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="plan_id">
                    Usuários simultâneos
                    <span id="tooltip-users-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-users-event" triggers="hover">
                      Escolha quantidade de usuários simultâneos
                    </b-tooltip>
                  </label>
                  <select
                    class="custom-select"
                    name="plan_id"
                    disabled
                    id="plan_id"
                    v-model="event.plan_id"
                    :class="{
                      'is-invalid': submitted && $v.event.plan_id.$error,
                    }">
                    <option v-for="(plan, i) in plans" :key="i" :value="plan.id">{{ plan.name }}</option>
                  </select>
                  <div
                    v-if="submitted && !$v.event.plan_id.required"
                    class="invalid-feedback"
                  >
                  O campo é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="description">
                    Descrição do evento
                    <span id="tooltip-description-event" class="link font-size-16"><i class="bx bx-info-circle"></i></span>
                    <b-tooltip target="tooltip-description-event" triggers="hover">
                      Breve descrição sobre o evento
                    </b-tooltip>
                  </label>
                  <textarea
                    id="description"
                    v-model="event.description"
                    name="description"
                    type="text"
                    rows="4"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && $v.event.description.$error,
                    }"
                  >
                  </textarea>
                  <div
                    v-if="submitted && !$v.event.description.required"
                    class="invalid-feedback"
                  >
                    O campo descrição é obrigatório
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="title">Data/Hora de início</label>
                  <date-picker
                    v-model="event.start_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                    :class="{
                      'is-invalid': submitted && $v.event.start_date.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.event.start_date.required"
                    class="invalid-feedback"
                  >
                  O campo Abertura é obrigatório
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="title">Data/Hora de término</label>
                  <date-picker
                    v-model="event.end_date"
                    type="datetime"
                    :first-day-of-week="1"
                    lang="pt-br"
                    format="DD/MM/YYYY HH:mm"
                    :class="{
                      'is-invalid': submitted && $v.event.end_date.$error,
                    }"
                  >
                  </date-picker>
                  <div
                    v-if="submitted && !$v.event.end_date.required"
                    class="invalid-feedback"
                  >
                  O campo Encerramento é obrigatório
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">Dados do administrador</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-centered">
                  <thead>
                    <tr>
                      <th width="60%">Nome</th>
                      <th width="30%">E-mail</th>
                      <th width="10%">Senha</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(usr, i) in event.user" :key="i">
                      <td>{{ usr.name }}</td>
                      <td>{{ usr.email }}</td>
                      <td>{{ usr.showPass ? usr.password : '********' }} <i class="bx bx-show-alt p-1 link" @click="showPassword(i)"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">Dados do domínio</h4>
            <div class="row">
              <div class="table-responsive">
                <table class="table table-centered">
                  <thead>
                    <tr>
                      <th width="70%">Nome</th>
                      <th width="15%">Segurança</th>
                      <th width="15%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(dns, i) in event.dns" :key="i">
                      <td>{{ dns.name }}</td>
                      <td><i class="mdi font-size-16" :class="{ 'mdi-cloud-check': dns.proxied, 'mdi-cloud-off-outline': !dns.proxied }"></i></td>
                      <td>
                        <span class="badge badge-pill font-size-12" :class="{
                          'badge-soft-success': `${dns.status}` === '1',
                          'badge-soft-danger': `${dns.status}` === '0',
                        }">{{ dns.status ? 'Publicado' : 'Offline' }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <button type="buton" @click="cancelar()" class="btn btn-dark me-1">
                Cancelar
              </button>
              <button type="button" @click="updateData()" class="btn btn-primary me-1">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import service from './services'

export default {
  components: {
    DatePicker,
    Layout,
    PageHeader
  },
  name: 'form-event',
  page: {
    title: 'Editar informações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      plans: [],
      event: {
        id: '',
        name: '',
        status: 0,
        description: '',
        user: {
          password: '',
          name: '',
          email: ''
        }
      },
      submitted: false,
      title: 'Editar Evento',
      items: [{
        text: 'Meus Eventos',
        href: '/events'
      },
      {
        text: 'Editar Evento',
        active: true
      }
      ]
    }
  },
  validations: {
    event: {
      name: { required },
      description: { required },
      start_date: { required },
      plan_id: { required },
      end_date: { required }
    }
  },
  mounted () {
    this.loadItem()
    this.getPlans()
  },
  computed: {
    ...mapState('events', { data: 'event' }),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('events', {
      fetch: 'getEvent',
      postData: 'save'
    }),
    showPassword (idx) {
      this.event.user[idx].showPass = !this.event.user[idx].showPass
    },
    getPlans () {
      service.listPlans({}).then(({ data }) => {
        this.plans = data.data
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
      })
    },
    cancelar () {
      this.$router.push({ name: 'events' })
    },
    loadItem () {
      if (this.$route.params.id !== undefined) {
        let loader = this.$loading.show()
        this.items[1].text = 'Editar Evento'
        this.fetch(this.$route.params.id)
          .then(() => {
            this.event = this.data
            this.event.start_date = this.$moment(this.event.start_date, 'YYYY-MM-DD HH:mm').toDate()
            this.event.end_date = this.$moment(this.event.end_date, 'YYYY-MM-DD HH:mm').toDate()
            if (this.data.users) {
              this.event.user = this.data.users.data
            }
            if (this.data.dns) {
              this.event.dns = this.data.dns.data
            }
          }).then(() => {
            loader.hide()
          })
      }
      let loader = this.$loading.show()
      loader.hide()
    },
    async updateData () {
      this.submitted = true
      this.$v.event.$touch()
      if (this.$v.event.$invalid) {
        window.scroll(0, 0)
        return
      }
      let loader = this.$loading.show()
      this.event.start_date = this.$moment(this.event.start_date).format('YYYY-MM-DD HH:mm:ss')
      this.event.end_date = this.$moment(this.event.end_date).format('YYYY-MM-DD HH:mm:ss')

      await this.postData(this.event).then((data) => {
        let message = 'Sucesso: Ação registrada.'
        let delay = 2500
        successMessage(message, delay).then(() => {
          loader.hide()
          this.cancelar()
        })
      }).catch((err) => {
        console.log(err)
        errorMessage('Alerta: ' + err.message)
        this.submitted = false
        loader.hide()
      })
    }
  },
  watch: {}
}
</script>

<style scoped>
  .domain-eventfy {
    padding: 20px;
    gap: 1rem;
    text-align: center;
    padding-top: 15%;
    max-height: 35rem;
    height: 23rem;
    background: #FFFFFF;
    border: 1px solid #45506F;
    box-sizing: border-box;
    box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.01);
    border-radius: 7px;
    margin: 2rem;
    cursor: pointer;
  }
  .domain-eventfy:hover {
    background: #eaefff;
  }
  .active {
    background: #eaefff;
  }
</style>
