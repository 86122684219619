var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Dados do evento")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Nome do evento "),_c('span',{staticClass:"link font-size-16",attrs:{"id":"tooltip-name-event"}},[_c('i',{staticClass:"bx bx-info-circle"})]),_c('b-tooltip',{attrs:{"target":"tooltip-name-event","triggers":"hover"}},[_vm._v(" Informe um nome para o seu evento ")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.name),expression:"event.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.event.name.$error,
                  },attrs:{"id":"name","name":"name","type":"text"},domProps:{"value":(_vm.event.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.event.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo nome é obrigatório ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"plan_id"}},[_vm._v(" Usuários simultâneos "),_c('span',{staticClass:"link font-size-16",attrs:{"id":"tooltip-users-event"}},[_c('i',{staticClass:"bx bx-info-circle"})]),_c('b-tooltip',{attrs:{"target":"tooltip-users-event","triggers":"hover"}},[_vm._v(" Escolha quantidade de usuários simultâneos ")])],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.plan_id),expression:"event.plan_id"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.event.plan_id.$error,
                  },attrs:{"name":"plan_id","disabled":"","id":"plan_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.event, "plan_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.plans),function(plan,i){return _c('option',{key:i,domProps:{"value":plan.id}},[_vm._v(_vm._s(plan.name))])}),0),(_vm.submitted && !_vm.$v.event.plan_id.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v(" Descrição do evento "),_c('span',{staticClass:"link font-size-16",attrs:{"id":"tooltip-description-event"}},[_c('i',{staticClass:"bx bx-info-circle"})]),_c('b-tooltip',{attrs:{"target":"tooltip-description-event","triggers":"hover"}},[_vm._v(" Breve descrição sobre o evento ")])],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.description),expression:"event.description"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.event.description.$error,
                  },attrs:{"id":"description","name":"description","type":"text","rows":"4"},domProps:{"value":(_vm.event.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "description", $event.target.value)}}}),(_vm.submitted && !_vm.$v.event.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo descrição é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Data/Hora de início")]),_c('date-picker',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.event.start_date.$error,
                  },attrs:{"type":"datetime","first-day-of-week":1,"lang":"pt-br","format":"DD/MM/YYYY HH:mm"},model:{value:(_vm.event.start_date),callback:function ($$v) {_vm.$set(_vm.event, "start_date", $$v)},expression:"event.start_date"}}),(_vm.submitted && !_vm.$v.event.start_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo Abertura é obrigatório ")]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Data/Hora de término")]),_c('date-picker',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.event.end_date.$error,
                  },attrs:{"type":"datetime","first-day-of-week":1,"lang":"pt-br","format":"DD/MM/YYYY HH:mm"},model:{value:(_vm.event.end_date),callback:function ($$v) {_vm.$set(_vm.event, "end_date", $$v)},expression:"event.end_date"}}),(_vm.submitted && !_vm.$v.event.end_date.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo Encerramento é obrigatório ")]):_vm._e()],1)])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-2"},[_vm._v("Dados do administrador")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"60%"}},[_vm._v("Nome")]),_c('th',{attrs:{"width":"30%"}},[_vm._v("E-mail")]),_c('th',{attrs:{"width":"10%"}},[_vm._v("Senha")])])]),_c('tbody',_vm._l((_vm.event.user),function(usr,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(usr.name))]),_c('td',[_vm._v(_vm._s(usr.email))]),_c('td',[_vm._v(_vm._s(usr.showPass ? usr.password : '********')+" "),_c('i',{staticClass:"bx bx-show-alt p-1 link",on:{"click":function($event){return _vm.showPassword(i)}}})])])}),0)])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-2"},[_vm._v("Dados do domínio")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"70%"}},[_vm._v("Nome")]),_c('th',{attrs:{"width":"15%"}},[_vm._v("Segurança")]),_c('th',{attrs:{"width":"15%"}},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.event.dns),function(dns,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(dns.name))]),_c('td',[_c('i',{staticClass:"mdi font-size-16",class:{ 'mdi-cloud-check': dns.proxied, 'mdi-cloud-off-outline': !dns.proxied }})]),_c('td',[_c('span',{staticClass:"badge badge-pill font-size-12",class:{
                        'badge-soft-success': ("" + (dns.status)) === '1',
                        'badge-soft-danger': ("" + (dns.status)) === '0',
                      }},[_vm._v(_vm._s(dns.status ? 'Publicado' : 'Offline'))])])])}),0)])])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"float-end"},[_c('button',{staticClass:"btn btn-dark me-1",attrs:{"type":"buton"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary me-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateData()}}},[_vm._v(" Salvar ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }